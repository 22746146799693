/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { Homepage } from "../../../components/homepage"

export const pageQuery = graphql`
  query pyrzowice {
    file(relativePath: { eq: "parking-lotnisko-pyrzowice-front.jpeg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 585, height: 439)
      }
    }
    posts: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___order_parking] }
      filter: { frontmatter: { template: { eq: "parking-post" } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            slug
            title
            isParkVia
            doNotShowOnMainPage
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 345, height: 260)
              }
            }
          }
        }
      }
    }
  }
`

const frontmatter = {
  template: "index-page",
  slug: "/",
  title: "Parkingi Lotnisko Pyrzowice Katowice",
  tagline: "Pyrzowice, Polska",
  featuredImage: "/assets/parking-lotnisko-pyrzowice-front.jpeg",
  cta: {
    ctaText: "Wyszukaj parking",
    ctaLink: "#search",
  },
}
const HomePage = ({ data }) => (
  <Homepage
    frontmatter={frontmatter}
    data={data}
    descriptionComponent={
      <p>
        <b>Adres:</b> Wolności 90, 42-625 Ożarowice Międzynarodowy Port Lotniczy
        Katowice im. Wojciecha Korfantego w Pyrzowicach. Katowice Wojciech
        Korfanty Airport, kod IATA: KTW, kod ICAO: EPKT
      </p>
    }
  >
    <p>
      Parking Pyrzowice to najlepsze miejsce na postój samochodu podczas Twojej
      podróży lotniczej. Oferujemy wygodne i bezpieczne miejsca parkingowe w
      pobliżu lotniska w atrakcyjnych cenach. Zarezerwuj miejsce już dziś i
      ciesz się spokojem w czasie podróży!
    </p>
    <p>
      Planujesz swoją podróż samolotem z lotniska w Pyrzowicach, ale nie wiesz
      gdzie zostawić swój samochód? Parking Pyrzowice jest idealnym rozwiązaniem
      dla Ciebie! Parkingi są bezpieczne. Niektóre z nich oferują ochronę 24/7,
      ogrodzenie, monitoring 24/7 i wiele innych. Dzięki nam będziesz mógł
      skupić się na przyjemności związanej z podróżą, bez martwienia się o swoje
      auto.
    </p>
    <h2>
      <strong>Dlaczego warto skorzystać z Parking Pyrzowice?</strong>
    </h2>
    <p>Korzystając z naszych parkingów, zyskujesz wiele korzyści:</p>
    <h3>
      <strong>Bezpłatny transfer</strong>
    </h3>
    <p>
      Zapewniamy bezpłatny transfer na lotnisko Pyrzowice, który jest dostępny
      przez całą dobę. Nasi kierowcy odezwą się do Ciebie przed przyjazdem na
      parking, aby ustalić godzinę odbioru.
    </p>
    <h3>
      <strong>Bezpieczeństwo</strong>
    </h3>
    <p>
      Parking Pyrzowice jest monitorowany 24/7, co oznacza, że Twoje auto będzie
      bezpieczne przez cały czas Twojej nieobecności. Ponadto, nasz teren jest
      ogrodzony i oświetlony, co dodatkowo zwiększa poziom bezpieczeństwa.
    </p>
    <h3>
      <strong>Wygoda</strong>
    </h3>
    <p>
      Nasze miejsca parkingowe są wygodne i łatwo dostępne, co oznacza, że
      szybko i bez problemów odnajdziesz swoje auto po powrocie z podróży.
    </p>
    <h3>
      <strong>Atrakcyjna cena</strong>
    </h3>
    <p>
      Parking Pyrzowice oferuje konkurencyjne ceny za wynajem miejsca
      postojowego w pobliżu lotniska.
    </p>
    <h2>
      <strong>Jak zarezerwować miejsce na Parking Pyrzowice?</strong>
    </h2>
    <p>
      Rezerwacja miejsca na naszym parkingu jest bardzo prosta. Możesz to zrobić
      online za pomocą naszej strony internetowej . W obu przypadkach wymagane
      jest podanie daty i godziny przyjazdu oraz odjazdu, a także numeru
      rejestracyjnego Twojego samochodu.
    </p>
    <h2>
      <strong>Często zadawane pytania</strong>
    </h2>
    <h3>
      <strong>Jak długo przed wylotem powinienem przyjechać na parking?</strong>
    </h3>
    <p>
      Zalecamy, aby przyjechać na parking przynajmniej 2 godziny przed
      planowanym wylotem.
    </p>
    <h3>
      <strong>
        Czy można zarezerwować miejsce na parking na dłuższy czas?
      </strong>
    </h3>
    <p>
      Tak, nasz parking oferuje możliwość wynajmu miejsca postojowego na dłuższy
      okres czasu. Skontaktuj się z nami, aby uzyskać szczegółowe informacje na
      temat ceny i dostępności.
    </p>
    <h3>
      <strong>Czy transfer na lotnisko jest bezpłatny?</strong>
    </h3>
    <p>
      Tak, transfer na lotnisko Pyrzowice jest bezpłatny dla klientów parkingu.
    </p>
    <h3>
      <strong>Czy muszę zostawić kluczyki do mojego samochodu?</strong>
    </h3>
    <p>
      Nie, nie ma takiej konieczności. Możesz zabrać swoje kluczyki ze sobą.
    </p>
    <h3>
      <strong>Jakie są formy płatności za wynajem miejsca postojowego?</strong>
    </h3>
    <p>
      Akceptujemy płatności gotówkowe oraz kartą płatniczą. Można również
      dokonać płatności online przez naszą stronę internetową.
    </p>
    <p>
      Parking Pyrzowice to idealne miejsce dla tych, którzy chcą mieć pewność,
      że ich samochód jest bezpieczny podczas ich nieobecności. Oferujemy
      konkurencyjne ceny za wynajem miejsca postojowego w pobliżu lotniska
      Pyrzowice, a także bezpłatny transfer na lotnisko. Rezerwacja miejsca na
      naszym parkingu jest bardzo prosta i wymaga tylko kilku kliknięć.
      Zarezerwuj już dziś miejsce na naszym parkingu i ciesz się spokojem w
      czasie podróży!
    </p>
  </Homepage>
)

export default HomePage
